import { render, staticRenderFns } from "./CalendarMonth.vue?vue&type=template&id=20577b35&scoped=true"
import script from "./CalendarMonth.vue?vue&type=script&lang=js"
export * from "./CalendarMonth.vue?vue&type=script&lang=js"
import style0 from "./CalendarMonth.vue?vue&type=style&index=0&id=20577b35&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20577b35",
  null
  
)

export default component.exports