<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Randevu Takvimi</h3>
        <div style="position: absolute; right: 10px">
          <button @click="openHelperVideo" class="btn" title="Bilgi edin"><i class="fa fa-info-circle"  style="font-size:25px;color: rgb(187, 116, 148);" ></i></button>
          <div
            class="modal fade"
            id="videoHelper"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <video controls>
                  <source
                    src="https://storage.terapivitrini.com/storage/profile/randevu_takvimi.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <CalendarMonth  />
      </div>
      <div class="card-footer"></div>
    </div>
  </div>
</template>
<style>
body {
  --grey-100: #e4e9f0;
  --grey-200: #e9cad9;
  --grey-300: #b5c0cd;
  --grey-800: #3e4e63;
  --grid-gap: 1px;
  --day-label-size: 20px;
}

ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.calendar-month-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>

<script>
import CalendarMonth from "./CalendarMonth.vue";
export default {
  name: "CalendarView",
  created() {
    document.title = "Randevu Takvimi";
  },
  data() {
    return {
      pageGet: [],
    };
  },
  methods: {
    openHelperVideo() {
      $("#videoHelper").modal("show");
    },
  },
  components: {
    CalendarMonth,
  },
  mounted() {},
};
</script>