<template>
  <li
  title="Randevu Saatlerini Planla"
    @click="slcDate(day.isCurrentMonth, isToday)"
    class="calendar-day"
    :style="styleProceess(data)"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isToday,
    }"
  >
    <span>{{ label }}</span>
  </li>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "CalendarMonthDayItem",

  props: {
    day: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
    isCurrentMonth: {
      type: Boolean,
      default: false,
    },

    isToday: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    styleProceess(data) {
      if (data != null) {
        if (data.available == 1 || data.hourList) {
          return "background:rgb(197 138 166);color:white;";
        } else {
          return "background:rgb(252 210 230);";
        }
      }
    },
    slcDate(out, ins) {
      if (out && (!ins || ins)) {
        let date = this.day.date.split("-");
        this.$router.push(
          "/profile/calendar/" +
            date[0] +
            "/" +
            date[1] +
            "/" +
            date[2] +
            "/update"
        );
      }
    },
  },
  computed: {
    label() {
      return dayjs(this.day.date).format("D");
    },
  },
};
</script>

<style scoped>
.calendar-day {
  position: relative;
  min-height: 100px;
  font-size: 16px;
  background-color: #fff;
  color: var(--grey-800);
  padding: 5px;
}
.calendar-day:hover {
  background: rgb(243, 239, 176);
  cursor: pointer;
}
.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2px;

  width: var(--day-label-size);
  height: var(--day-label-size);
}

.calendar-day--not-current {
  background-color: #fff6e9;
  color: var(--grey-300);
}
.calendar-day--not-current:hover {
  background-color: #fff6e9;
  cursor: no-drop;
}
.calendar-day--today {
  padding-top: 4px;
}

.calendar-day--today > span {
  border-radius: 9999px;
  color: #c58aa6;
  border-radius: 9999px;
  background-color: #fff6e9;
  padding: 20px;
}
</style>
