<template>
  <div class="calendar-month" >
    <div class="row" style="background:white;">
      <div class="col-sm-12 col-md-4 col-xl-4" style="margin-top:15px">
        <div style="width:25px; float:left; height:25px; border-radius:25px; background-color:rgb(197, 138, 166)"></div>
        <div style="float:left; margin-left:10px;line-height: 23px;">Planlanmış ve <b>aktif</b> gün</div>
      </div>
      <div class="col-sm-12 col-md-4 col-xl-4" style="margin-top:15px">
        <div style="width:25px; float:left; height:25px; border-radius:25px; background-color:rgb(252, 210, 230)"></div>
        <div style="float:left; margin-left:10px;line-height: 23px;">Planlanmış ve <b>pasif</b> gün</div>
      </div>
      <div class="col-sm-12 col-md-4 col-xl-4" style="margin-top:15px">
        <div style="width:25px; float:left; height:25px; border-radius:25px; background-color:#fff; border:1px solid #ccc"></div>
        <div style="float:left; margin-left:10px;line-height: 23px;">Planlanmamış gün</div>
      </div>

    </div>
    <div class="calendar-month-header">
      <CalendarDateIndicator
      
        :selected-date="selectedDate"
        class="calendar-month-header-selected-month"
      />

      <CalendarDateSelector
      
        :current-date="today"
        :selected-date="selectedDate"
        @dateSelected="selectDate"
      />
    </div>
    <CalendarWeekdays />
    <ol class="days-grid" style="border: 1px solid #e9cad9">
      <CalendarMonthDayItem
        v-for="day in days"
        :data="dateFind(day.date)"
        :key="day.date"
        :day="day"
        :is-today="day.date === today"
      />
    </ol>
    
  </div>
</template>

<script>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import CalendarMonthDayItem from "./CalendarMonthDayItem";
import CalendarDateIndicator from "./CalendarDateIndicator";
import CalendarDateSelector from "./CalendarDateSelector";
import CalendarWeekdays from "./CalendarWeekdays";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: "CalendarMonth",
  components: {
    CalendarMonthDayItem,
    CalendarDateIndicator,
    CalendarDateSelector,
    CalendarWeekdays,
  },

  data() {
    return {
      selectedDate: dayjs(),
      pageGet: [],
    };
  },
  mounted() {
    this.$store
      .dispatch("calendarList", {
        begin: this.currentMonthDays[0].date,
        end: this.currentMonthDays[this.currentMonthDays.length - 1].date,
      })
      .then((value) => {
        this.pageGet = value['calendar'];
      });
  },
  watch: {
    "currentMonthDays": function () {
      this.$store
        .dispatch("calendarList", {
          begin: this.currentMonthDays[0].date,
          end: this.currentMonthDays[this.currentMonthDays.length - 1].date,
        })
        .then((value) => {
          this.pageGet = value['calendar'];
        });
    },
  },
  computed: {
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ];
    },
    
    today() {
      return dayjs().format("YYYY-MM-DD");
    },

    month() {
      return Number(this.selectedDate.format("M"));
    },

    year() {
      return Number(this.selectedDate.format("YYYY"));
    },

    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },

    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => {
        return {
          date: dayjs(`${this.year}-${this.month}-${index + 1}`).format(
            "YYYY-MM-DD"
          ),
          isCurrentMonth: true,
        };
      });
    },

    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date
      );
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(
        1,
        "month"
      );

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0].date
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
        .date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => {
          return {
            date: dayjs(
              `${previousMonth.year()}-${previousMonth.month() + 1}-${
                previousMonthLastMondayDayOfMonth + index
              }`
            ).format("YYYY-MM-DD"),
            isCurrentMonth: false,
          };
        }
      );
    },

    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`
      );

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, "month");

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: dayjs(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
          ).format("YYYY-MM-DD"),
          isCurrentMonth: false,
        };
      });
    },
  },

  methods: {
    getWeekday(date) {
      return dayjs(date).weekday();
    },

    selectDate(newSelectedDate) {
      this.selectedDate = newSelectedDate;
    },
    dateFind(item){
      var i;
      this.pageGet.forEach(e=>{
        if(e.date==item){
          i=e
        }
      })
      return i;
    },
  },
};
</script>

<style scoped>
.calendar-month {
  position: relative;
  background-color: var(--grey-200);
}

.day-of-week {
  color: var(--grey-800);
  font-size: 18px;
  background-color: #fff;
  padding-bottom: 5px;
  padding-top: 10px;
}

.day-of-week,
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  text-align: right;
  padding-right: 5px;
}

.days-grid {
  height: 100%;
  position: relative;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
}
</style>
